<template>
  <div class="container">
    <van-tabs :ellipsis="false" title-style="nav-title" title-active-color="#ffffff" title-inactive-color="#AAAAAA"
      background="#333333" v-model="active" sticky swipeable @click="onClick">
      <van-tab v-for="(item, index) in list" :title="item.title" :key="index">
        <div class="txt">
          <div class="img-all">
            <img src="../../assets/report/all.png" @click="lookImg" alt />
          </div>
        </div> 
      </van-tab>
    </van-tabs>
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(ite, ind) in picList" :key="ind">
        <div class="center">
          <div class="imgName">{{ ite.name }}</div>
          <!-- <img :src="ite" alt />  width: 345px;
                height: 258px;-->
          <van-image v-if="ite.valueType != 1" fit="widthFix" :src="ite.value" @click="onImageClick(picList, ind)" />
          <van-image class="images" v-else fit="cover" @click="play(ite.value)" :src="ite.poster" />
          <div class="play" v-if="ite.valueType == 1" @click="play(ite.value)">
            <img src="../../assets/newReport/play.png" alt />
          </div>
        </div>
      </swiper-slide>
    </swiper>
      <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
  </div>
</template>
<script>
let _that = ''
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { report, reportImg } from "@/utils/http.js";
import { mapState } from "vuex";
import { ImagePreview } from "vant";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: [],
  data() {
    return {
      swiperOption: {
        loop: false,
        // 显示分页
        pagination: {
        },
        on: {
          slideChangeTransitionStart: function () {
            _that.picList.forEach((item, index) => {
              if (index == this.activeIndex) {
                console.log(item,index,this.activeIndex)
                _that.active = item.index
                console.log(_that,'===')
              }
            })
          },
        },
      },
      show: true,
      // current: 1,
      active: 0,
      list: [],
      startX: null,
      startXs: null,
      surrentStartX: null,
      surrentStartXs: null,
      nextPage: 1,
      picList: '',
    };
  },
  computed: {
    swiper() {
      //    thiswx.nextTick(() => {
      return this.$refs.mySwiper.swiper;
      //   });
    },
    ...mapState["imgList"],
  },
  watch: {},
  created() { },
  mounted() {
    _that = this
    this.list = this.$store.state.imgList;
    let list = []
    this.list.forEach((item, index) => {
      item.imgList.forEach(ite => {
        list.push(ite)
      })
    })
    console.log(this.list,list,'-----')
    this.picList = list
    if (this.$store.state.imgKey) {
      let keys = this.$store.state.imgKey;
      this.active = keys.id
      this.picList.forEach((item, index) => {
        if (item.name == keys.name) {
          console.log(item,index)
          this.$refs.mySwiper.swiper.slideTo(index, 500, false);
        }
      })
    }
  },
  methods: {
    // 点击图片
    onImageClick(list, index) {
      let arr = [];
      list.forEach((item) => {
        arr.push(item.value);
      });
      ImagePreview({
        images: arr,
        startPosition: index,
        showIndex: false,
      });
    },
    onClick(name, title) {
      console.log(this.active,this.list[this.active])
      console.log(this.list[this.active].imgList[0])
      let key = this.list[this.active].imgList[0]
      this.picList.forEach((item, index) => {
        if (item.name == key.name) {
          console.log(item,index)
          this.$refs.mySwiper.swiper.slideTo(index, 500, false);
        }
      })
    },
    lookImg() {
      this.$router.push("/imgList");
    },
    play(data) {
      console.log(data);
      this.$store.commit("setPlay", data);
      this.$router.push("/play");
    },
    call_back(){this.$router.go(-1);},
  },
  destroyed() {
    this.$store.commit("setImgKey", "");
  },
};
</script>
<style lang='less'   scoped>
.container {
  height: 100vh;
  background: #333333;
}

.centers {
  width: 70vw;
  height: 20vh;
  background: #ffffff;
  margin: 0px auto;
  color: #000000;
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}

.txt {
  width: 100vw;
  height: 100%;
  // position: relative;
}

.imgName {
  margin-bottom: 20px;
  text-align: center;
  font-size: 15px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}

.custom-indicator {
  color: #ffffff;
}

.center {
  width: 345px;
  margin: 0 auto;
  padding-top: 20px;
  color: #ffffff;
  padding-bottom: 40px;
}

/deep/ .center>.van-image {
  width: 345px;
  min-height: 258px;
  margin: 0 auto;
}

/deep/ .center>.van-image>img {
  border-radius: 5px;
}

.problem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 10px;
  font-size: 18px;
}

/deep/ .money {
  height: 20px;
  line-height: 20px;
  font-size: 14px !important;
}

.tips {
  font-size: 14px;
  color: #d0d0d0;
  margin-top: 15px;
  line-height: 18px;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 50px;
}

/deep/ .van-tab {
  font-size: 16px;
}

/deep/.van-tab--active>.van-tab__text {
  font-size: 19px;
}

/deep/ .van-tabs__line {
  width: 20px !important;
  height: 10px !important;
  background-color: transparent !important;
  background: url("../../assets/report/white.png") center center;
  background-size: 100% 100%;
}

.swiper-container {
  background: #333333;
}

.img-all {
  text-align: right;
  box-sizing: border-box;
  padding-right: 15px;
  margin-top: 20px;
}

.img-all>img {
  width: 20px;
  height: 20px;
}

.images {
  width: 345px !important;
  height: 258px !important;
}

.play {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 150px;
  left: 160px;
}

.play>img {
  width: 100%;
  height: 100%;
}
.back_img{
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>